.modal-load__container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10000;
  background: #00000063;
  top: 0;
  align-items: center;
  justify-content: center;
}

.modal-load__container.hide {
  display: none;
}

.modal-load__body {
  background: transparent;
  padding: 0.8rem;
  border-radius: 5px;
  min-width: 20%;
  text-align: center;
}
