.table-mobile__container {
  font-size: 1.4em;
}

.table-mobile__container.alwaysMobile {
  font-size: 1rem;
}

.table-mobile__item__container {
  background: #f1f4f6;
  border-radius: 8px;
  padding: 10px;
}

.table-mobile__line__container + .table-mobile__line__container {
  margin-top: 12px;
}

.table-mobile__line__header {
  color: rgba(16, 24, 40, 0.5);
}

.table-mobile__line__value {
  color: #101828;
}

.table-mobile__item__container {
  margin-top: 1em;
}

.table-mobile__container {
  margin-bottom: 1em;
}

.table-mobile__container {
  .input-title__container {
    margin-bottom: initial;
  }

  .input-title__label {
    display: none;
  }

  .input-title__container-main {
    border-color: white;
    background-color: white;
    padding: 5px 6px;
  }

  .input-title__block {
    padding: 0;
  }

  .input-title__description {
    display: none;
  }
}