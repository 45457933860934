.table-employee {
  .link-cell {
    max-width: 10px !important;
    padding-right: 0 !important;
    padding-top: 6px;
  }
}

@media (max-width: 768px) {
  .command {
    .h3-felix {
      font-size: 20px;
      line-height: 24px;
    }

    &_header + hr {
      margin: 12px 0 0;
    }

    table {
      margin-bottom: 32px;

      thead {
        border-bottom: 1px solid #CCCCCC;
      }

      th {
        font-size: 16px;
        line-height: 24px;
      }

      td {
        font-size: 13px;
        line-height: 20px;
      }

      tbody {
        tr:first-child {
          td {
            padding-top: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .command {
    .h3-felix {
      font-size: 16px;
      line-height: 20px;
    }

    &_header {
      flex-direction: column !important;
      margin-bottom: 20px;

      .h3-felix {
        margin-bottom: 8px !important;
      }

      .button_Link_borderNone {
        padding: 0;
        max-width: 270px;
        text-align: start;
      }
    }


    &_header + hr {
      display: none;
    }

    &_employees {
      margin-bottom: 42px;

      .command_employee:last-child {
        margin-bottom: 0;
      }
    }

    &_employee {
      margin-bottom: 20px;

      &__name {
        font-size: 16px;
        line-height: 24px;

        .command_employee__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .command_employee__icon_container {
          display: inline-flex;
          gap: 1rem;
        }

        .command_employee__title_name {
          display: flex;

          .command_employee__title_name_link {
            margin-left: 5px;
          }
        }
      }

      &__title, &__text {
        font-size: 14px;
        line-height: 20px;
      }

      &__title {
        font-weight: 600;
      }
    }

    &__load {
      .h5-felix-medium + hr {
        margin: 12px 0;
      }

      table + hr, .command_loadEmpty + hr {
        display: none;
      }

      .command_loadItem {
        margin-bottom: 40px;
      }
    }

    &__add {
      hr {
        margin: 12px 0;
      }
    }
  }
}