.table-spoiler-botton {
  margin-left: auto;
  border: none;
  background: none;

  &.open svg {
    transform: rotate(180deg);
  }
}

.table-sort-button {
  margin-left: 9px;

  &.desc {
    transform: rotate(180deg);
  }
}

.table-right {
  text-align: right;
}

.dashboard-result-table__container.contrast {

  table > thead > tr > th,
  table > tfoot > tr > td,
  table > tbody > tr > td {
    border: 1px solid #e8e8ee;
    border-radius: 0;
  }

  table > thead > tr > th,
  table > tfoot > tr > td {
    color: rgb(16 24 40 / 80%);
  }
}

.table-vertical-top > table > tbody > tr > td {
  vertical-align: top;
}

.dashboard-result-table__container,
.gantt_table__container {

  .inputField,
  .DatePicker__input {
    font-weight: inherit;
  }

  .input-title__container {
    margin-bottom: initial;
  }

  .input-title__label {
    display: none;
  }

  .input-title__container-main {
    background-color: inherit;
    padding: 0;
    border: none;
  }

  .input-title__block {
    padding: 0;
  }

  .input-title__description {
    display: none;
  }

  .input-select {
    .select-rt__indicator-separator {
      display: none;
    }

    .select-rt__indicators {
      margin: 0;
    }

    .select-rt__dropdown-indicator {
      padding: 0;
    }
  }

  .date-rt__container {
    .date-rt__indicators {
      top: 0;
      right: 0;
    }

    .select-rt__indicator-separator {
      display: none;
    }

    .select-rt__indicators {
      margin: 0;
    }

    .select-rt__dropdown-indicator {
      padding: 0;
    }

    .DatePicker.rt-calendar {
      width: 100px;
    }
  }
}

.max-width-table {
  display: inline-block;
  max-width: 100%;
  min-width: 100%;
  overflow: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}