$bar-color: #40A2FF !default;
$bar-danger-color: #ff6d6d !default;

@media print {
  .pdf {
    &.presentation__container {
      display: flex !important;
      width: 1400px;
    }

    .top-report-table-wrapper {
      .table-responsive {
        &.TOP {
          max-height: 765px !important;
        }

        &.KEY {
          max-height: 815px !important;
        }
      }
    }

    .top-report-project-bottom {
      flex: 0 0 auto;

      .top-report-project-gantt {
        max-width: 870px;

        .gantt-container {
          overflow: initial !important;
          max-height: 640px !important;
        }
      }

      .top-report-project-status {
        max-width: 250px !important;
        max-height: 680px;
        overflow: hidden !important;
      }
    }
  }
}

.frame-list-title {
  font-size: 30px !important;
  margin-bottom: 0 !important;
}

.top-report-indicator-container-tooltip{
  display: inline-flex;
}

.top-report-tooltip-indicator {
  margin: 5px;
}

.top-report {
  .color-indicator.status-report {
    width: 18px;
    height: 18px;
    margin: 5px;
  }

  .frame-project-title {
    margin-bottom: 0 !important;
    max-width: 80%;
  }

  .input-title__container {
    margin-bottom: 5px !important;
  }

  .input-title__container-main {
    background-color: #F4F4F5;
  }
}

.top-report-footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.col-24 {
  flex: 0 0 auto;
  width: 20%;
}

.presentation__container.mobile .presentation__nav_button.top-report-nav-button {
  padding: 1.25em;
}

.top-report-filters {
  margin-top: 15px;
  margin-bottom: -15px;
}

.top-report-status-consolidate {
  margin: 0 -10px;
}

.top-report-table-wrapper {
  .table-responsive {
    &.TOP {
      max-height: calc(100vh - 315px) !important;
    }

    &.KEY {
      max-height: calc(100vh - 245px) !important;
    }
  }

  .spacer {
    height: 4px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 0;
  }

  th {
    background-color: #F4F4F5 !important;
    color: #9EA0A6 !important;
    text-align: left;
    vertical-align: middle !important;
  }

  th,
  td {
    border-width: 1px !important;
    border-color: #D3D3D3 !important;
    border-style: solid solid solid none !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  th:first-child,
  td:first-child, {
    border-left-style: solid !important;
    border-top-left-radius: 13px !important;
    border-bottom-left-radius: 13px !important;
  }

  th:last-child,
  td:last-child, {
    border-top-right-radius: 13px !important;
    border-bottom-right-radius: 13px !important;
  }

  .table-sort-button {
    margin-left: 0;

    path {
      fill: #9EA0A6 !important;
    }
  }

  .top-report-table-id {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button_Link_borderNone {
    font-size: unset;
  }
}

.project-name-text {
  margin-right: 15px;
}

.project-name-text-link {
  cursor: pointer;
}

.project-name-text-link:hover {
  color: var(--primary-color);
}

.project-name-link {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.top-report-project-data {
  color: #4E4E4E;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 0 5px;
  height: 40px;

  .top-report-project-data-left {
    display: flex;
    flex-wrap: wrap;

    .top-report-project-data-block {
      margin-right: 20px;
    }
  }

  .top-report-project-data-right {
    display: flex;
    flex-direction: column;
    max-width: 20%;

    .top-report-project-data-block {

    }
  }
}

.top-report-project-bottom {
  display: flex;
  justify-content: space-between;

  .top-report-project-gantt {
    max-width: 940px;

    .project-gantt-title {
      font-size: 18px;
      font-weight: bold;
    }

    .gantt_container {
      margin-left: 0;
    }

    .gantt-container {
      overflow: initial;
      max-height: calc(100vh - 495px) !important;
    }
  }

  .top-report-project-status {
    min-width: 400px;
    max-width: 410px;
    max-height: calc(100vh - 450px);
    overflow: auto;

    .status-card {
      background-color: #F4F4F5;
      border-radius: 7px;
      padding: 5px 10px;
      margin-bottom: 15px;

      .status-card-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        vertical-align: middle;
      }

      .status-card-group {
        margin-bottom: 10px;
      }

      .status-card-block-group {
        display: flex;
        flex-wrap: wrap;
        max-width: 380px;

        .status-card-block {
          font-weight: bold;
          background-color: white;
          border-radius: 7px;
          padding: 2px 8px;
          width: 185px;
          margin: 2px;
        }
      }
    }
  }
}

.presentation__footer_left__container {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;

  .presentation__nav__container {
    margin-right: 1rem;
  }

  .presentation__nav__count {
    margin: 0 !important;
  }
}

.dashboard-chart__container {
  width: 100%;
  margin-top: 0;
  justify-content: center;
}

@media (max-height: 600px) {
  .top-report-table-wrapper {
    .table-responsive {
      &.TOP,
      &.KEY {
        max-height: none !important;
      }
    }
  }

  .top-report-project-bottom {
    .top-report-project-status {
      max-height: none !important;
    }
  }

  .top-report-project-bottom {
    .top-report-project-gantt {
      .gantt-container {
        max-height: none !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .top-report-project-bottom {
    .top-report-project-gantt {
      max-width: calc(100% - 470px);
    }
  }
}