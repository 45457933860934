.workList_content__empty {
  margin: 20px 0;
}

.workList_edit__switch {
  span {
    white-space: nowrap;
  }
}

@media (max-width: 1200px) {
  .workList_filters {
    &__item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 1100px) {
  .workList_edit__switchContainer {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .workList__dateInput {
    flex: 0 0 100%;
    max-width: 100%;  
  }
}

@media (max-width: 768px) {
  .workList__contentTitle {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .workList_filters {
    &__content {
      background: #F4F4F5;
      padding: 0 20px 12px !important;
      border-radius: 0 0 8px 8px;
    }

    &__title {
      font-size: 16px;
      line-height: 24px;
      border: 2px solid rgba(16, 24, 40, 0.1);
      box-sizing: border-box;
      padding: 10px 20px !important;
      background-color: #fff !important;
    }

    &__item {
      .input-title__container-main {
        background-color: rgba(16, 24, 40, 0.05);
        border-color: 1px solid rgba(16, 24, 40, 0.05);
      }
    }

    &__clear {
      width: 100%;
      background: transparent !important;
      padding: 10px 30px !important;
    }

    &.accordion-item-felix-open {
      .workList_filters__title {
        background-color: #F4F4F5 !important;
        border-color: #F4F4F5;
      }
    }
  }
}

@media (max-width: 600px) {
  .workList__contentTitle {
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 12px !important;
  }
}
