@media print {

  .main-panel {
    width: 100%;
  }

  .wrapper {
    height: 100%;
  }

  // .dashboard-new.card {
  //   page-break-after: always;
  // }
}
