.content.user-account__container {
  background-color: white;
  display: flex;
}

.user-account__container_center {
  margin: 0 auto;
  width: 500px;
  margin-top: 40px;
}

.user-account__displayName {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1rem;
}
