
// .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
//     border-top: 1px solid #ddd !important;
// }
.table.table-felix > tbody > tr > td,
.table.table-felix > tbody > tr > th,
.table.table-felix > tfoot > tr > td,
.table.table-felix > tfoot > tr > th,
.table.table-felix > thead > tr > td,
.table.table-felix > thead > tr > th {
  border: none;
}

.table-felix tr{
  font-size: 13px;
}
.table-felix tr td:first-child{
  padding-left: 0 !important;
}
.table-felix tr th:first-child{
  padding-left: 0 !important;
}
.table-felix th:first-child{
  // min-width: 70px;
  padding-right: 30px;
}
tr.tableEditRow{
  position: relative;
}
.tableEditRow:hover .button-visible{
  opacity: 1;
}
tr.tableEditRow td:last-child{
  width: 20px;
}

.table th, .table td {
  padding: 0.5rem;
}
