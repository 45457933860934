.card {
  border: 1px solid $light-gray;
  border-radius: 4px;
  background: white;
  width: 100%;
  color: #101828;
  height: 100%;
  &-title {
    margin: 0;
    color: #101828;
    font-weight: 300;
    margin-top: 18px;
    margin-bottom: 10px;
    font-size: 1.3rem;
    word-wrap: break-word;
    font-weight: 400;
  }

  .rt-card-subtitle {
    font-size: 12px;
    color: #101828;
    margin-bottom: 10px;
  }
  .card-text {
    font-size: 12px;
    line-height: 16px;
  }
  .card-height {
    min-height: 200px;
  }

  .form-group label,
  fieldset label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    &.control-label {
      color: #25265f;
      text-transform: uppercase;
    }
    &.error {
      text-transform: none;
      color: #fb404b;
      margin-top: 5px;
    }
  }
}
