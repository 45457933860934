.indiana-scroll-container {
  margin-bottom: 1rem;

  .gantt-container {
    overflow: initial;
    max-height: 60vh;
  }

  .table-responsive {
    overflow-x: initial;
  }

  .dashboard-result-table__container .table-responsive {
    max-height: initial;
  }

  &.max-height-500 {
    max-height: 500px;
  }
}
