.committee {
  .dashboard-result-table__container,
  .table {
    overflow: auto;

    th {
      white-space: nowrap;
    }
  }

  &__tableNew {
    min-width: 1000px;
  }

  &__tableList {
    display: table !important;
  }

  &__buttons {
    margin-top: 20px;
  }

  &__docs {
    margin-top: 20px;
  }
}

@media (max-width: 1300px) {
  .committee {
    &__filter {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media (max-width: 1100px) {
  .committee {
    &__filter {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .committee {
    &__tableList {
      display: block !important;
      min-width: 500px;
    }
  }
}

@media (max-width: 600px) {
  .committee {
    .card-title {
      margin: 20px !important;
      padding: 0 !important;

      .dashboard__card-title__exportXls {
        justify-content: space-between;
        width: 100%;
      }
    }

    .card-body {
      padding: 0 20px 20px !important;
    }

    .dashboard-new.card .dashboard__card-spoiler.closed + .card-body {
      padding: 0 !important;
    }

    .rt-felix-button__container {
      width: 100%;
    }

    &__filter {
      &:first-child {
        margin-top: 20px;
      }

      .input-title__container {
        font-size: 14px;
        line-height: 20px;
      }

      &__date {
        .input-title__container {
          font-size: 12px;
        }
      }
    }
  }
}
