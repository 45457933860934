.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  margin-top: 124px;
  width: 650px;
  min-width: 514px;
  background: #ffffff;
  padding: 60px 113px;
  border-radius: 8px;

  @media (--xs) {
    padding: 64px 16px;
    width: 100%;
  }
}

.pageSelector {
  display: flex;
  border-bottom: 1px solid #e4e9ed;
}

.pageSelectorItem {
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-right: 32px;
  transform: translateY(1px);
  font-weight: 400;
}

.pageSelectorItem:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.pageSelectorItem.active {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.pageSelectorItem.active:hover {
  color: var(--primary-color);
}
