
//checkbox toggle

label.switch-felix {
  position: relative;
  display: flex;
  font-size: 16px;
  width: 100%;
  font-weight: 100;
  
  .switch-slider-felix {
    margin-left: .5rem;
  }
}

.switch-input-felix {
  opacity: 0;
  width: 0;
  height: 0;
  display: none; 
}

.switch-slider-felix {
  height: 24px;
  width: 44px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  flex-shrink: 0;
}

.switch-slider-felix:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-input-felix:checked + .switch-slider-felix {
  background-color: var(--primary-color);
}

.switch-input-felix:focus + .switch-slider-felix {
  box-shadow: 0 0 1px var(--primary-color);
}

.switch-input-felix:checked + .switch-slider-felix:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

//checkbox arrow

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  border: 1.5px solid var(--primary-color);
  border-radius: 3px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;

}
