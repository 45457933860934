.accordion-felix {
  width: 100%;
  max-width: 768px;
}
.accordion-item-felix,
.accordion-item-felix-open {
  border-radius: 8px;
  transition: all 0.5s ease;
}
.tab-title-felix {
  position: relative;
  font-size: 16px;
  cursor: pointer;
  padding-right: 25px;

  .input-title__tooltip {
    right: 1.5rem;
  }
}
.tab-title-felix::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url('../../images/arrow-bottom2.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.5s ease;
}

.arrow-list-item {
  content: ' ';
  position: relative;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-image: url('../../images/arrow-bottom2.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.5s ease;

  &.open {
    transform: rotate(180deg);
  }
}

.accordion-item-felix > .tab-data-felix {
  display: none;
}
.accordion-item-felix.accordion-item-felix-open > .tab-data-felix {
  display: block;
}

.tab-data-felix {
  opacity: 0;
  max-height: 0;
  // overflow: hidden;
  // transition: all 0.5s ease;
}
.accordion-item-felix-open > .tab-title-felix::after {
  transform: translateY(-50%) rotate(180deg);
}
.accordion-item-felix-open > .tab-data-felix {
  opacity: 1;
  max-height: inherit;
}

.accordion-item-felix__link > .tab-title-felix {
  padding-right: 27px;
}

.accordion-item-felix__link > .tab-title-felix::after {
  top: 12px;
  height: 100%;
}

.accordion-item-felix.filter {
  margin-bottom: 1rem;

  .tab-title-felix {
    background-color: #F4F4F5;
    border-radius: 8px;
    padding: 5px 1rem;
  }

  .tab-title-felix::after {
    right: 0.5rem;
  }

  .tab-data-felix {
    padding: 1rem 1rem 0 1rem;
  }

  &:not(.accordion-item-felix-open) {
    .tab-data-felix {
      padding: 0;
    }
  }

  &.accordion-item-felix-open  {

    .tab-title-felix {
      border-radius: 8px 8px 0 0;
    }

    .tab-data-felix {
      border: 1px solid #F4F4F5;
      margin-top: 0px;
    }

  }

}

@media (max-width: 768px) {
  .accordion-item-felix,
  .accordion-item-felix.filter {
    margin-bottom: 12px;
  }
}

@media (max-width: 600px) {
  .accordion-item-felix,
  .accordion-item-felix.filter {
    margin-bottom: 20px;
  }

  .accordion-item-felix-open.accordion-item-felix-highlight {
    background: rgb(119 0 255 / 5%);
    padding: 10px;
  }
}