.card-briefcase {
  padding: 40px 60px;
  min-height: 200px;
}

@media (max-width: 768px) {
  .card-briefcase {
    padding: 20px;
    min-height: 160px;
  }
}

@media (max-width: 600px) {
  .card-briefcase {
    padding: 20px;
    min-height: 140px;
  }
}