.status-indicator {
  display: flex;
  flex-flow: wrap;
  & div {
    margin-right: 10px;
    width: autos;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid rgba(16, 24, 40, 0.1);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.status-indicator div {
  font-size: 11px;
  height: 16px;
  margin-bottom: 10px;
}

.new_project_indicator {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .item {
    margin-top: 5px;
    margin-right: 10px;
    width: auto;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project__status-container {
    margin-right: 10px;
    display: inline-block;
  }

  .project__status-item {
    font-size: 12px;
  }
}

.status-indicator div:first-child {
  border: none;
}
.greenStatus,
.yellowStatus,
.orangeStatus {
  color: white;
}
.greenStatus {
  background-color: #00d359;
}
.redStatus {
  background-color: #ff4747;
}
.yellowStatus {
  background-color: #ffb608;
}
.orangeStatus {
  background-color: #ff4f12;
}
.grayStatus {
  background-color: #b5b7c0;
  color: #101828;
}
.grayStatusWhiteText {
  height: 16px;
  background-color: #b5b7c0;
  color: white;
}

.influence-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .new_project_indicator {
    flex-direction: column;
    align-items: flex-start;
  
    .item {
      padding: 0;
      line-height: 20px;
    }
  
    .project__status-container {
      margin-right: 0;
    }
  }
}