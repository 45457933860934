//input-file

.rt-custom-file-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  height: 48px;
  border: 2px solid rgba(16, 24, 40, 0.25);
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  margin-bottom: 1rem;
  line-height: 24px;
  color: rgba(16, 24, 40, 0.25);
  transition: all 500ms ease;
  margin-left: 1rem;
  border: 2px solid var(--primary-color);
  color: #101828;
  opacity: 0.8;
  cursor: pointer;
}

.input-file-felix.disable .rt-custom-file-label {
  color: black;
  border-color: black;
  opacity: 0.4;
}

button.input-file-felix__remove.text,
.input-file-felix__remove {
  color: red;
  cursor: pointer;
  padding: 0px 7px;
  margin: 0;
}

.input-file-felix input {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  display: block;
  height: 25px;
  width: 25px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .custom-search-input.search-admin {
    width: 100% !important;
  }
}

.custom-search-input {
  position: relative;

  &.search-admin {
    width: 100%;

    .search-clear {
      right: calc(42px);
    }
  }

  .search-query {
    width: 100%;
    height: 46px;
    padding-right: 35px;
    padding-left: 15px;
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 8px;
    background: url('../../images/Header/1.svg') rgba(16, 24, 40, 0.05) no-repeat calc(100% - 10px) center;
    border-radius: 0;
    border: 0;
    cursor: text;

    &-withValue {
      padding-right: calc(5% + 64px);
    }
  }
}

.search-query:focus {
  z-index: 0;
}

.search-clear {
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: calc(5% + 32px);
  transform: translateY(-50%);
  cursor: pointer;
}

.custom-search-input .input-group input {
  border-radius: 8px;
}

input {
  outline: none;
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: #868b95;
  padding-left: 0 !important;
  padding-bottom: 0;
}

.input-edit-felix {
  border: none;
  width: 100%;
  padding: 8px 0 0;

  .no-padding {
    padding: 0;
  }
}

//checkbox

.checkbox {
  position: relative;
  margin-bottom: 1rem;
}

.checkbox__input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}

.checkbox__label {
  font-size: 1rem;
  color: #444;
  cursor: pointer;
}

.checkbox__label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;

  border: 1px solid var(--primary-color);
  background-color: #fff;

  position: absolute;
  top: 2px;
  left: 0;

  transition: background 0.1s linear, border 0.1s linear;
}

.checkbox__label.disabled:before {
  border-color: gray;
}

.checkbox__label:after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;

  background: url('../../images/check.svg') no-repeat;
  background-size: 12px 12px;
  opacity: 0;

  position: absolute;
  top: 4px;
  left: 2px;

  transition: opacity 0.1s linear;
}

.checkbox__input:checked + .checkbox__label:after,
.checkbox__label.checked:after {
  opacity: 1;
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}


.date-rt__container {
  .select-rt__indicator.select-rt__clear-indicator {
    padding-top: 7px;
  }
}

.input-rt__container input.inputField {
  width: calc(100% - 1rem);
}

.date-rt__container,
.input-rt__container {
  position: relative;

  .date-rt__indicators,
  .input-rt__indicators {
    display: flex;
    position: absolute;
    top: -13px;
    right: -15px;
  }

  .select-rt__clear-indicator {
    padding-right: 2rem;
    cursor: pointer;
  }

  .select-rt__clear-indicator-svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }

  .select-rt__indicator {
    color: hsl(0, 0%, 80%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    box-sizing: border-box;

    &.table{
      padding-top: 0;
    }
  }

  .select-rt__indicator-separator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
  }

  .select-rt__indicator:hover {
    color: hsl(0, 0%, 60%);
  }

  .select-rt__indicator svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }

  textarea.input-rt.clearable {
    padding-right: 1rem;
  }
}

.DatePicker.rt-calendar {
  .holiday:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):not(:hover) {
    color: red !important;
  }

  .workingDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    color: black !important;
  }
}

.form-disabled-gray {

  .inputField:disabled,
  .input-rt:disabled,
  .date-rt__container.pointer-events-none input {
    color: rgb(153, 153, 153);
  }
}