body > .DatePicker.rt-calendar {
  position: absolute;
  z-index: 1060;
}

.DatePicker.rt-calendar {
  z-index: 0;
  width: 185px;
  position: initial;

  .DatePicker__calendarContainer__wrap {
    position: relative;
    z-index: 1001;
  }

  .DatePicker__input {
    background: transparent;
    border: none;
    text-align: left;
    padding: 0;
    font-size: inherit;
    width: 100%;
  }

  .Calendar {
    z-index: 1;
    min-height: 30.7em;
  }

  .Calendar__day {
    border-radius: 8px;
    margin-bottom: 0px;
  }

  .Calendar__day.-selectedBetween {
    border-radius: 0;
  }

  .Calendar__day.-selectedBetween {
    color: rgba(16, 24, 40, 0.7);
  }

  .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    color: var(--primary-color);
    font-weight: normal;
  }

  .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: var(--primary-color);
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.9);
  }

  .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    display: none;
  }

  .Calendar__sectionWrapper {
    min-height: 24.3em;
  }

  .Calendar__section {
    padding: 0 20px;
  }

  .Calendar__weekDays {
    padding: 0 20px;
    color: rgba(16, 24, 40, 0.25);
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .Calendar__weekDay {
    text-decoration: none;
  }

  button.Calendar__monthText {
    color: #101828;
  }

  button.Calendar__yearText {
    color: rgba(16, 24, 40, 0.75);
  }

  .Calendar__section.-hiddenPrevious {
    transform: translateX(-100%);
  }

  .Calendar__section.-hiddenNext {
    transform: translateX(100%);
  }

  .DatePicker__calendarContainer {
    top: calc(100% + 5px);
    z-index: 1001;
  }

  .DatePicker__calendarArrow {
    top: calc(100% + -5px);
  }

  .DatePicker__calendarContainer.-top + .DatePicker__calendarArrow {
    bottom: calc(100% + 25px);
    top: auto;
  }

  .DatePicker__calendarContainer.-top {
    bottom: calc(100% + 35px);
    top: auto;
  }
}

@media (max-width: 768px) {
  .DatePicker.rt-calendar {
    .DatePicker__calendarContainer__wrap {
      margin-left: 110px;
    }
  }
}

@media (max-width: 390px) {
  .DatePicker.rt-calendar {
    .DatePicker__calendarContainer__wrap {
      margin-left: 50%;
    }
  }
}

@media (max-width: 360px) {
  .DatePicker.rt-calendar {
    .DatePicker__calendarContainer__wrap {
      margin-left: 40%;
    }
  }
}

@media (max-width: 330px) {
  .DatePicker.rt-calendar {
    .DatePicker__calendarContainer__wrap {
      margin-left: 30%;
    }
  }
}