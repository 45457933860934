.navbar-wrapper {
  display: flex;
  height: $navbarHeight;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-start;
  a, div{
    display: flex;
    align-items: center;
  }

  svg {
    flex-shrink: 0;
  }
}
.notification {
  width: auto;
}

.profile {
  width: auto;
  min-width: 70px;
}

.search-navbar {
  width: auto;
  background-position: left;
  & input {
    width: 70px;
    height: auto;
    border: none;
  }
}

.header-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  background-color: white;
  @media (max-width: 600px) {
    height: 56px;
  }
}

.navbar-item + .navbar-item {
  margin-left: 2rem;
}

@media (max-width: 1200px) {
  .navbar {
    padding: 0;
    padding-right: 20px;
  }

  .navbar-item + .navbar-item {
    margin-left: 20px;
  }

  .navbar-wrapper {
    a, div {
      font-size: 14px;
      line-height: 20px;
    }

    a span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .profile {
      max-width: 220px;
      min-width: unset;

      svg {
        margin-right: 3px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .navbar-item + .navbar-item {
    margin-left: 10px;
  }

  .navbar-wrapper {
    .profile {
      max-width: 110px;
    }
  }
}

@media (max-width: 850px) {
  .navbar-item + .navbar-item {
    margin-left: 40px;
  }

  .navbar-wrapper {
    a span {
      display: none;
    }

    .profile {
      max-width: 100%;

      span {
        display: inline-block;
      }
    }

    .signOut {
      margin-left: 10px;
    }
  }
}

@media (max-width: 768px) {
  .navbar-item + .navbar-item {
    margin-left: 5%;
  }
}

@media (max-width: 650px) {
  .navbar-item + .navbar-item {
    margin-left: 3%;
  }
} 
