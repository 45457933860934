.rt-tree-select {
  &.select-tree-fix {
    position: absolute;
    top: calc(50% - 1em);
    right: calc(0.1em - 2px);;
  }

  .rc-tree-select-tree-switcher {
    .select-rt__indicator-separator {
      display: none;
    }
  }

  &.rc-tree-select-multiple {

    .rc-tree-select-selector {
      border: none;
    }

    .rc-tree-select-selector .rc-tree-select-selection-item {
      background-color: hsl(0,0%,90%);
      border-radius: 2px;
      box-sizing: border-box;
      padding: 2px 5px;
      font-size: 85%;
    }

    .rc-tree-select-selection-overflow {
      padding: 0 1rem;
      padding-right: 2.5rem;
    }

    .rc-tree-select-selector .rc-tree-select-selection-overflow-item {
      line-height: 18px;
      margin-right: 1px;
    }

    .select-rt__multi-value__remove:hover {
      background-color: #FFBDAD;
      color: #DE350B;
    }

    .select-rt__multi-value__remove {
      align-items: center;
      border-radius: 2px;
      display: inline;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
      margin-right: -4px;
      margin-left: 4px;
    }

    .rc-tree-select-selector .rc-tree-select-selection-search-input {
      background: transparent;
      padding: 0;
      margin-left: 0;
    }
  }


  &.rc-tree-select {
    margin-left: -16px;
    width: calc(100% + 2rem);
    font-size: inherit;
  }

  &.rc-tree-select-single .rc-tree-select-selector .rc-tree-select-selection-search {
    width: 90%;
  }

  .rc-tree-select-selection-search-input {
    border: none;
    background: transparent;
    margin-left: 14px;
  }

  &.rc-tree-select-single .rc-tree-select-selector .rc-tree-select-selection-item,
  &.rc-tree-select-single .rc-tree-select-selector .rc-tree-select-selection-placeholder {
    left: 1rem;
  }

  &.rc-tree-select-dropdown {
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    border: none;
    border-radius: 4px;
    min-height: inherit;
  }

  .rc-tree-select-tree-iconEle {
    display: none !important;
  }

  .rc-tree-select-tree-treenode {
    // padding: 8px 12px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #DEEBFF;
    }
  }

  .rc-tree-select-tree-title {
    padding: 7px 6px;
  }

  .rc-tree-select-tree .rc-tree-select-tree-treenode .rc-tree-select-tree-node-content-wrapper {
    height: inherit;
    width: 100%;
  }

  .rc-tree-select-tree-treenode.rc-tree-select-tree-treenode-selected {
    background-color: #2684FF;
    color: white;
  }

  .rc-tree-select-tree-node-selected {
    background: transparent;
    box-shadow: none;
  }

  .rc-tree-select-empty {
    color: rgb(153, 153, 153);
    padding: 8px 12px;
    text-align: center;
    box-sizing: border-box;
  }

  &.rc-tree-select-show-arrow .rc-tree-select-arrow {
    user-select: none;
    top: -12px;
    right: 0px;
    cursor: pointer;

  }

  &.rc-tree-select-allow-clear .rc-tree-select-clear {
    top: calc(50% - 25px);
    right: 38px;
  }

  .select-rt__indicator-separator {
    align-self: stretch;
    position: absolute;
    background-color: #cccccc;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
    height: 22px;
    top: -3px;
    right: 37px;
  }


  .select-rt__indicator {
    color: hsl(0,0%,80%);
    display: flex;
    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;

    &:hover {
      color: hsl(0,0%,60%);
    }

    svg {
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
    }
  }

  .rc-tree-select-tree {
    border: none;
  }

  .rc-tree-select-tree-switcher {
    margin-left: 8px;
  }

  &.rc-tree-select-disabled .rc-tree-select-selector {
    opacity: 0.8;
  }

}
