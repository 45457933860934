@mixin button-loader($color, $important: null) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color}" id="icon-refresh" aria-label="refresh icon" viewBox="0 0 512 512"><path d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"></path></svg>') $important;
}

@mixin button-finished($color, $important: null) {
  background-image: url('data:image/svg+xml;utf8,<svg fill="none" id="icon-check" aria-label="check icon" stroke="#{$color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17l-5-5"></path></svg>') $important;
}

@mixin button-error($color, $important: null) {
  background-image: url('data:image/svg+xml;utf8,<svg fill="none" id="icon-check" aria-label="check icon" stroke="#{$color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" xmlns="http://www.w3.org/2000/svg"><path d="m2,2,10,10 m0,-10,-10,10"></path></svg>') $important;
}

.button-felix {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  border: none;
  color: white;
  height: 48px;
  min-width: 32%;
  padding: 0 20px;
  border-radius: 8px;
}

.button-outline-felix {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
  color: #101828;
  height: 48px;
  min-width: 32%;
  border-radius: 8px;
  background: white;
}

.button-outline-oracle-felix {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
  color: #101828;
  font-size: 0.8rem;
  height: 32px;
  min-width: 32%;
  border-radius: 8px;
  background: white;
}

.button-outline-oracle-felix:hover {
  cursor: pointer;
}

.width33 {
  width: 33%;
}

.button_Link_borderNone_delete:hover {
  color: var(--primary-color);
}

.button-outline-add-felix {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
  color: #101828;
  height: 48px;
  min-width: 187px;
  border-radius: 8px;
  background: white;

  & svg {
    display: block;
    margin: 0 10px;
    width: 24px;
    flex-shrink: 0;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-left: 1px solid rgba(16, 24, 40, 0.05);
  }

  &.disabled {
    border-color: black;
    opacity: 0.4;
  }
}

.button-felix-group {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  .rt-felix-button__container.right {
    margin-left: auto;
  }
}

.button-felix-group.multiline {
  margin: -0.3rem;
  flex-wrap: wrap;

  .margin-left-multiple {
    margin: 0.3rem;
  }
}


.link_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.link_button {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.link_button:hover {
  cursor: pointer;
}

.button_Link_borderNone {
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  line-height: 20px;
  background: transparent;
  outline: none;
  padding: 0;

  &.uncolored {
    color: unset;
  }

  &:visited {
    color: var(--primary-color);
  }

  &:hover {
    color: var(--primary-color);
  }
}

button.button_Link_borderNone:hover {
  cursor: pointer;
}

.button_Link_borderNone_delete {
  border: none;
  color: rgba(16, 24, 40, 0.5);
  font-size: 14px;
  line-height: 20px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
}

.budget_remove_button.button-visible {
  position: relative;
}

tr:hover span.team-load-table__remove_btn {
  opacity: 1;
}

button.rt-felix-button__container.team-load-table__remove_btn {
  position: absolute;
  cursor: pointer;
  padding: 0;
  color: gray;

  &:hover {
    color: var(--primary-color);
  }
}

.button-visible {
  position: absolute;
  padding: 8px;
  opacity: 0;
  transition: 0.5s ease;
  // right: 0px;
}

.button-visible:hover {
  cursor: pointer;
}

.svg_button_edit {
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

.svg_button_edit_release {
  margin-bottom: 1rem;
  color: red;

  &:hover {
    cursor: pointer;
  }
}

.svg_button_edit_work_group {
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.edit-delete-button-group-felix {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;

  & .delete-button-felix {
    color: #ff4747;
    cursor: pointer;
  }
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modalButton {
  min-width: 100px;
}

button.button-outline-add-felix > div {
  padding: 0 10px;
}

@media (max-width: 600px) {
  .button-felix-group {
    flex-direction: column;

    .margin-left-multiple {
      margin-left: 0 !important;
    }
  }

  .button-outline-add-felix {
    width: 100%;
    white-space: nowrap;
    font-size: 14px;
  }

  .edit-delete-button-group-felix {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;

    .edit-button-felix {
      margin-bottom: 10px;
    }
  }
}