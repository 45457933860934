$header-height: 60px;

.gantt_table__container {

  td,
  th {
    padding: 0.3rem 0.5rem;
  }

  td {
    border-bottom: 1px solid #e7e7e7;
    height: 25px;
    white-space: nowrap;

    &.icon-cell {
      padding: 0.2rem 0.1rem;
    }
  }

  tr:last-of-type td {
    border-bottom: none;
  }

  th {
    height: $header-height;
    background: #F4F4F5;
    border-bottom: none;
    border-top: none;
    color: rgba(16, 24, 40, 0.5);
    border-left: 1px solid white;
    font-weight: 400;
    text-wrap: wrap;
  }

  tr:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .table-drag-item {
    padding-right: 0;
  }

  .gantt-row-title__header {
    margin-left: 0.8em;
  }

  .button-append {
    border: none;
    background: none;
    font-size: 32px;
    display: block;
    height: 0px;
    width: 22px;
    margin-top: -22px;
    padding: 0 8px;
  }

  .gantt-row-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gantt-row-title {
    position: relative;
    margin-left: 0.8em;
    display: block;

    .canExpand::before {
      content: '↓';
      margin-left: -0.8em;
      position: absolute;
    }

    .canExpand.expanded::before {
      content: '→';
    }

    .text {
      //max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .executor-display-name {
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.gantt__container {
  position: relative;
}

.gantt-date__conatiner {
  position: sticky;
  top: 0;
  z-index: 1;
}

.gantt-scale-row {
  display: flex;
  height: $header-height;
  background-position: 0px -8px;
  background-color: white;

  .gantt-scale-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F5;
    color: rgba(16, 24, 40, 0.5);
    border-right: 1px solid #e7e7e7;
  }

  .gantt-scale-cell.holiday {
    background: rgba(0, 0, 0, .07);
  }
}

.gantt-items {
  position: relative;
  background-position: 0px 0px;
}

.gantt-item__container {
  height: 18px;
  position: absolute;
  background-color: #acacac;
  color: white;
  padding: 2px 2px;

  &.date-end-init {
    padding: 2px;
    background-color: #FFA200;
  }

  .gantt-item__title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 11px;
    position: relative;
  }

  &:not(.milestone) {
    border-radius: 5px;

    .gantt-item__progress {
      position: absolute;
      height: 100%;
      background: rgba(0, 0, 0, 0.15);
      top: 0;
      left: 0;
      border-radius: 5px;
    }
  }

  .gantt-item__resize {
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: e-resize;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}

.gantt-item__container:not(.group).milestone {
  background: transparent !important;
  padding: 3px 0;

  &.EXPIRED {
    .gantt-item__title {
      background-color: #e61212 !important;
    }
  }

  &.MILESTONE_SUCCESS {
    .gantt-item__title {
      background-color: #41c151
    }
  }

  &.MILESTONE_SUCCESS_WITH_COMMENT {
    .gantt-item__title {
      background-color: #41c151
    }
  }

  &.blue {
    .gantt-item__title {
      background-color: #6f99c3
    }
  }

  &.gray {
    .gantt-item__title {
      background-color: #acacac;
    }
  }

  &.light-gray {
    .gantt-item__title {
      background-color: #e0e0e0;
    }
  }

  &.green {
    .gantt-item__title {
      background-color: #41c151
    }
  }

  &.red {
    .gantt-item__title {
      background-color: #e61212;
    }
  }

  .gantt-item__title {
    transform: rotate(45deg);
    background-color: #acacac;
    width: 12px;
    height: 12px;
    font-size: 0;

    &.blue {
      background-color: #6f99c3
    }

    &.gray {
      background-color: #acacac;
    }

    &.light-gray {
      background-color: #e0e0e0;
    }

    &.green {
      background-color: #41c151
    }

    &.red {
      background-color: #e61212;
    }
  }

  .gantt-item__text {
    color: black;
    top: -15px;
    left: 20px;
    white-space: nowrap;
  }

  .gantt-item__resize {
    display: none;
  }
}

.gantt-item__container.group {
  height: 14px;
  margin-top: 3px;
  padding: 0 12px;

  .gantt-item__resize {
    display: none;
  }

  &:after {
    content: " ";
    border: 3px solid black;
    height: 50%;
    position: absolute;
    width: calc(100% + 10px);
    top: -5px;
    left: -5px;
    border-bottom: none;
  }
}

.gantt-link__container {
  position: absolute;
  top: 0;
  margin-top: $header-height;
}

svg.gantt-link {
  width: 100%;
  height: 100%;

  polyline {
    stroke: orange;
    fill: transparent;
    stroke-width: 2;

    &.CPM {
      stroke: #FF33CC;
    }
  }
}

svg.milestone-gantt-line {
  width: 100%;
  height: 100%;

  polyline {
    stroke: black;
    fill: transparent;
    stroke-width: 1;
  }
}

.gantt-item__connect {
  position: absolute;
  width: 16px;
  height: 18px;
  top: 0px;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  &::after {
    content: " ";
    position: absolute;
    top: 3px;
    width: 12px;
    height: 12px;
    background: #6a53a3c7;
    border-radius: 50%;
    cursor: pointer;
  }

  &.right::after {
    left: 4px;
  }

  &.left {
    left: -16px;
  }

  &.right {
    right: -16px;
  }
}

.gantt-item__container:hover .gantt-item__connect {
  opacity: 1;
}

.gantt-panel__container {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: scroll;

  .scroll-fix tbody {
    max-height: calc(500px - $header-height);
  }
}

.split__container {
  display: flex;

  .split_splitter {
    position: relative;
    flex: 0 0 8px;
    background: #f1f1f1;
    cursor: col-resize;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .split__item {
    overflow: hidden;
  }

  .hide-scrollbar-vertical {
    margin-right: -15px;
  }

}

.gantt-main__container {
  position: relative;
  font-size: 12px;
}

.gantt-item-edit__container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
}

.gantt .gantt-item-edit__container.isMobile {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  margin: 0;
  padding: 0;
  overflow: auto;

  .gantt-edit__container {
    margin-right: 0;
    width: initial;
    max-height: initial;
  }
}

.gantt-edit__container {
  background: white;
  padding: 1rem;
  margin-right: 0.5rem;
  width: 400px;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%), 0 1px 7px 0 rgb(0 0 0 / 10%);
  max-height: 500px;
  overflow: auto;
}

.gantt_container {
  .tabs-button__container {
    display: inline-block;
  }
}

.tabs-button__container.gantt {
  display: inline-block;
  margin-right: 0.6rem;
}

.gantt-navbar {
  background: rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 2px;
  display: inline-block;
  margin-bottom: auto;
  user-select: none;
  margin-right: 0.6rem;

  .gantt-navbar-item {
    display: inline-block;
    border-radius: 6px;
    padding: 4px 6px;
    font-size: 13px;
    line-height: 20px;
    color: rgba(16, 24, 40, 0.7);
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    line-height: 1rem;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:not(.disabled):hover,
    &.active {
      background: #FFFFFF;
      color: #101828;
      box-shadow: 0px 0px 1px rgb(28 41 61 / 20%), 0px 2px 4px rgb(28 41 61 / 12%);
    }
  }

  &.primary {
    background-color: var(--primary-color);

    .gantt-navbar-item,
    .gantt-navbar-item a {
      color: white;
    }

    .gantt-navbar-item:not(.disabled):hover {
      background: var(--primary-color);
      color: white;

      svg path {
        fill: white;
      }
    }
  }
}

.gantt-navbar-item + .gantt-navbar-item::before {
  position: absolute;
  width: 1px;
  height: 20px;
  background: #dbdbdb;
  content: "";
  left: 0;
}

.gantt.fullscreen {
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 12;
  background: white;
  width: 100vw;
  height: calc(100vh - 64px);
  padding: 0.5rem;

  .gantt-panel__container {
    max-height: calc(100vh - 112px);
  }

  .scroll-fix tbody {
    max-height: calc(100vh - 112px - $header-height);
  }

  .gantt-edit__container {
    max-height: calc(100vh - 150px);
  }

}

@media (min-width: 600px) and (max-width: 1000px) {
  .gantt.fullscreen {
    left: 52px;
    width: calc(100% - 52px);
  }
}


.gantt.fullscreen.isTablet {
  left: 52px;

  .gantt-panel__container {
    max-height: calc(100vh - 160px);
  }

  .scroll-fix tbody {
    max-height: calc(100vh - 160px - $header-height);
  }
}

.gantt.fullscreen.isMobile {
  .gantt-panel__container {
    max-height: calc(100vh - 350px);
  }

  .scroll-fix tbody {
    max-height: calc(100vh - 350px - $header-height);
  }
}

.gantt-edit__container {
  .col-md-6 {
    width: 100%;
  }

  .saveDoc {
    display: block;

    .rt-custom-file-label {
      margin: 0;
    }
  }
}

.dashboard-result-table__container.gantt-item-link {
  table > tbody > tr > td {
    position: relative;
  }
}

.gantt-item__container.readonly {

  .gantt-item__resize,
  .gantt-item__connect {
    display: none;
  }
}

.checkbox.without-label {
  padding-left: 15px;
  line-height: 1.4;

  .checkbox__label:before {
    border-color: rgb(16 24 40 / 90%);
  }
}

.gantt_navbar__container {
  .dashboard__switch {
    //display: inline-block;
    padding: 0;
    margin: 0;

    label {
      margin-top: 10px;
    }
  }
}

.work-link-add-button {
  margin-bottom: 8px;
  margin-left: 15px;
}

.resizer {
  background: rgba(16, 24, 40, 0.1);
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  /* prevent scroll on touch devices */
  touch-action: none;

  cursor: col-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: -1px;
  color: black;
}

.isResizing {
  background: rgba(16, 24, 40, 0.2);
}

.scroll-fix thead tr:after {
  content: '';
  overflow-x: scroll;
  overflow-y: scroll;
  visibility: hidden;
  height: 0;
  width: 0;
}

.scroll-fix tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}