.presentation__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: white;
  display: flex;
  flex-direction: column;
}

.presentation__logo {
  margin-right: auto;
}

.presentation__head__container {
  box-shadow: 0px 0px 8px rgb(28 41 61 / 5%), 0px 2px 4px rgb(28 41 61 / 6%);
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.presentation__body__container {
  flex-grow: 1;
  // align-items: center;
  display: flex;
  padding: 0 1rem;
  padding-top: 1rem;
  overflow: auto;
}

.presentation__body__item {
  // margin: auto 0;
  margin: 0 auto;
  //display: flex;
}

.presentation__footer__container {
  padding: 1rem;
}

.presentation__nav_button {
  background: #e8e8ee;
  border-radius: 8px;
  padding: 0.75em;
  border: none;

  &:hover:not([disabled]) {
    background: #dcdce1;
  }

  &[disabled] {
    svg path {
      fill: #b5b7c0;
    }
  }

  &.right {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.presentation__nav_button + .presentation__nav_button {
  margin-left: 20px;
}

.presentation__nav__count {
  margin: 0 1rem;
  color: #797e8b;
}

.presentation__container {
  h1 {
    font-size: 2.625em;
    margin-bottom: 0.5714285714285714em;
    font-weight: 700;
    color: #101828;
  }

  h2 {
    font-size: 1.75em;
    font-weight: 700;
    margin-bottom: 0.75em;
  }

  h3 {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 1em;
  }

  h4 {
    font-size: 1.12em;
    color: #797e8b;
    font-weight: 400;
  }

  h1,
  h2,
  h3,
  h4 {
    .cnt {
      color: rgba(16, 24, 40, 0.5);
      display: inline-block;
    }
  }

  p {
    font-size: 1.25em;
  }

  div + h3 {
    margin-top: 2em;
  }

  ol {
    list-style: decimal;
    padding-left: 1rem;
    max-width: 56em;
  }

  .project__status-item {
    font-size: 1em;
  }

  .presentation__name {
    font-size: 5em;
    margin-bottom: 0.3em;
  }

  .no-padding {
    padding: 0;
  }

  .dashboard-result-table__container table > thead > tr > th,
  .dashboard-result-table__container table > tbody > tr > td,
  .dashboard-result-table__container table > tfoot > tr > td {
    font-size: 0.875em;
  }

  .dashboard-result-table__container.mini table > thead > tr > th,
  .dashboard-result-table__container.mini table > tfoot > tr > td,
  .dashboard-result-table__container.mini table > tbody > tr > td {
    padding: 0.25em 0.5em;
    vertical-align: top;
  }

  .color-indicator {
    width: 0.7272727272727273em;
    height: 0.7272727272727273em;
    transform: none;
  }
}

.presentation__status-item {
  font-size: 1.25em;
}

.presentation__team-list__label {
  color: #797e8b;
  font-size: 1.375em;
}

.presentation__team-list__value {
  font-size: 1.375em;
}

.presentation__team-list__item {
  margin-bottom: 1.85em;
}

.presentation__title__container {
  justify-content: flex-end;
}

.presentation__project-title__container {
  color: #3d4555;
  border-right: 1px solid #e8e8ee;
  padding-right: 0.75em;
  margin-right: 0.75em;
}

.presentation__project-type__container {
  color: #797e8b;
}

.presentation__goal-list__container {
  display: flex;
  align-items: flex-start;
  font-size: 1.375em;
  margin-bottom: 0.9090909090909091em;
}

.presentation__goal-list__label {
  background: var(--primary-color);
  color: white;
  padding: 0 0.45454545454545453em;
  margin-right: 0.9090909090909091em;
}

.presentation__lession__container {
  background-color: #f4f4f5;
  border-radius: 16px;
  padding: 1.25em;
}

.presentation__card__container {
  background-color: #f4f4f5;
  border-radius: 16px;
  white-space: break-spaces;
  font-size: 1.375em;
  padding: 0.9090909090909091em;

  &.narrow {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &.max-height {
    height: 100%;
  }

  &.light {
    background-color: transparent;
    border: 1px solid #e8e8ee;

    .color-indicator-text {
      font-weight: bold;
      margin-right: 0.5em;
    }
  }

  h3 {
    font-size: 1em;
    margin-bottom: 0.4em;
  }

  h2 {
    font-size: 1.2727272727272727em;
  }

  p {
    font-size: 1em;
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 1em !important;
  }

  .mb-2 {
    margin-bottom: 0.5454545454545454em !important;
  }

  .mt-1 {
    margin-top: 1em !important;
  }

  .mt-2 {
    margin-top: 0.5454545454545454em !important;
  }

  .mb-none {
    margin-bottom: 0 !important;
  }

  .item + .item {
    margin-top: 0.9090909090909091em;
  }
}

.presentation__card__container + .presentation__card__container {
  margin-top: 1.25em;
}

.star-item {
  path {
    fill: #b5b9c3;
  }

  &.checked path {
    fill: #0acb5b;
  }
}

.star-container {
  margin-bottom: 0.4em;
}

.star-item + .star-item {
  margin-left: 0.4em;
}

.presentation__title-parents__container {
  margin-bottom: 1.5em;
  color: #101828;
}

.presentation__title-parents {
  font-size: 1.375em;
}

.presentation__title-status__container {
  margin-bottom: 2.5em;

  .project__status-item {
    font-size: 1.25em;
    padding: 0.3em 0.6em;
  }
}

.presentation__page__center {
  margin: auto;
}

.presentation-list__container {
  margin-bottom: 2.5em;
}

.presentation__font-dec,
.presentation__font-inc,
.presentation__close {
  margin-left: 1rem;
}

.presentation__font-inc,
.presentation__font-dec {
  cursor: pointer;
}

.presentation-lession__title {
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 0;
  }
}

.presentation-lession__status {
  margin-left: auto;

  .project__status-item {
    font-size: 0.9090909090909091em;
    padding: 0.2727272727272727em 0.5454545454545454em;
    background-color: var(--primary-color);
    color: white;
  }
}

.presentation-budget__footnotes {
  display: flex;
  font-weight: 400;
  font-size: 0.875em;
  color: rgba(16, 24, 40, 0.8);
  margin-bottom: 1em;

  p {
    margin-bottom: 0;
    font-size: 1em;
  }

  p + p {
    margin-left: 1.25em;
  }
}

.dashboard-result-table__container table > tbody > tr > td.presentation-budget__table-header {
  font-size: 1em;
  font-weight: 700;
}

.pdf.presentation__container {
  position: initial;
  display: initial;
}

.stacked-container {

  & > * {
    margin-left: 0 !important;
    margin-right: 0.6em;
    margin-bottom: 0.6em;
  }
}

@media print {
  @page {
    size: A4 landscape;
    max-height: 100%;
    max-width: 100%;
  }

  html,
  body {
    -webkit-print-color-adjust: exact !important;
    font-size: 12px;
    position: unset;
    overflow: unset;

    .inner-body {
      width: unset;
      height: unset;
    }
  }

  .pagebreak {
    page-break-after: always;
  }

  .break-before {
    page-break-before: always !important;
    break-before: always !important;
  }

  .break-after {
    page-break-after: always !important;
    break-after: always !important;
  }

  .col-md-1 {
    width: 8%;
  }

  .col-md-2 {
    width: 16%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33%;
  }

  .col-md-5 {
    width: 42%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58%;
  }

  .col-md-8 {
    width: 66%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83%;
  }

  .col-md-11 {
    width: 92%;
  }

  .col-md-12 {
    width: 100%;
  }

}

.pdf {
  .presentation__graph__container {
    width: 1000px;
  }
}

.presentation__graph {
  .dashboard-new.card.noSpoiler .dashboard__card-title__container {
    margin: 0;
  }

  .dashboard-new.card.noSpoiler .card-title + .card-body {
    padding: 0;
  }
}

.presentation__container:not(.mobile) .presentation__wrapper {
  width: 1360px;
}

.presentation__container.mobile {
  font-size: 10px;

  .presentation__wrapper {
    min-width: 736px;
  }

  .presentation__name {
    font-size: 1.8em;
  }

  .presentation__title-parents {
    font-size: 1.4em;
  }

  .presentation__title-status__container .project__status-item {
    font-size: 1em;
  }

  .presentation__title-status__container {
    margin-bottom: 1.4em;
  }

  .presentation__team-list__item {
    margin-bottom: 0.8em;
  }

  .presentation__nav_button {
    padding: 0.8em;
  }

  .presentation__nav__count {
    font-size: 1.2em;
  }

  .presentation__title__container {
    margin: 0;
    justify-content: flex-start;
    font-size: 1.2em;
    margin-top: 0.4em;
  }

  h1 {
    font-size: 1.6em;
  }
}

.presentation__background__container {
  align-content: flex-start;
}

.presentation__container:not(.pdf) {

  .presentation-work__container {
    position: fixed;
    top: 80px;
    left: 20px;
    width: calc(100% - 40px);
  }
}