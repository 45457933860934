.react-bootstrap-table table {
  table-layout: initial;
}

.table > :not(:first-child) {
  border-top: initial;
}

.dashboard-result-table__container.table-responsive {
    max-height: 80vh;

  &.no-header {
    table {
      thead {
        display: none;
      }
    }
  }

}

.table-container__count {
  margin-top: 0.5em;
  font-size: 14px;
  text-align: right;
}