.wrapper-option__mvo {
  .mvo_dataRequest_textarea {
    position: relative;
  }

  .mvo_period_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;

    .mvo_period_select {
      width: 48%
    }
  }
}

@media (max-width: 768px) {
  .wrapper-option__mvo {
    .spoiler-list__container {
      margin-bottom: 32px;

      .spoiler-list__title {
        font-size: 20px;
        line-height: 24px;
        margin: 24px 0 32px;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper-option__mvo {
    .mvo_period_container {
      flex-direction: column;

      .mvo_period_select {
        width: 100%
      }
    }

    .spoiler-list__container {
      .spoiler-list__title {
        font-size: 16px;
        line-height: 20px;
        margin: 20px 0;
      }
    }
  }
}
