.notify__container {
  width: 100%;
  background: #F4F4F5;

  .h1-felix {
    margin-bottom: 0;
  }

  .notify-title {
    display: flex;
    justify-content: space-between;
  }
}

.notify-list__item {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 40px;
  margin-top: 20px;
  display: flex;

  &.unread .notify-list__info::after {
    content: " ";
    position: absolute;
    width: 8px;
    height: 8px;
    background: var(--primary-color);
    top: 6px;
    left: -16px;
    border-radius: 50%;
  }
}


.notify-list__info {
  flex-grow: 1;
  position: relative;
}

.notify-list__info {
  font-size: 14px;
  padding-right: 1rem;
}

.notify-list__date {
  font-size: 10px;
  line-height: 16px;
}

.admin-system__container {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .notify__container {
    padding-top: 40px !important;

    .h1-felix {
      margin-top: 0 !important;
    }

    .notify-list {
      &__item {
        padding: 20px 40px;
        flex-direction: column-reverse;
      }

      &__date {
        margin-bottom: 5px;
      }
    }
  }
}

@media (max-width: 600px) {
  .notify__container {
    padding-top: 20px !important;

    .notify-list { 
      &__item.unread {
        position: relative;

        &::after {
          content: " ";
          position: absolute;
          width: 8px;
          height: 8px;
          background: var(--primary-color);
          top: 12px;
          right: 12px;
          border-radius: 50%;
        }
      }

      &__info {
        &::after {
          display: none;
        }
      }
    }
  }
}