td:not(.no-text-color) {
  .first {
    &.different,
    &.different > * {
      text-decoration: line-through;
    }
  }

  .second {
    &.append,
    &.append > * {
      color: #00d359;
    }

    &.different,
    &.different > * {
      color: #ff5e2d;
    }
  }
}


