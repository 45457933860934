.team-load__container {
  font-size: 13px;
}

.table-team-load__container {
  &.table th, &.table td {
    padding: 0.2rem;
  }

  .input-title__container > td {
    padding-bottom: 1rem;
  }
}
