@font-face {
    font-family: 'GolosText';
    src: url('../fonts/golos-text_vf.woff2') format('woff2'),
    url('../fonts/golos-text_vf.woff') format('woff'),
    url('../fonts/golos-text_vf.ttf') format('truetype');
    font-style: normal;
    font-weight: 300
}

@font-face {
    font-family: 'GolosText';
    src: url('../fonts/golos-text_regular.woff2') format('woff2'),
    url('../fonts/golos-text_regular.woff') format('woff'),
    url('../fonts/golos-text_regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: 'GolosText';
    src: url('../fonts/golos-text_medium.woff2') format('woff2'),
    url('../fonts/golos-text_medium.woff') format('woff'),
    url('../fonts/golos-text_medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500
}

@font-face {
    font-family: 'GolosText';
    src: url('../fonts/golos-text_demibold.woff2') format('woff2'),
    url('../fonts/golos-text_demibold.woff') format('woff'),
    url('../fonts/golos-text_demibold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600
}

@font-face {
    font-family: 'GolosText';
    src: url('../fonts/golos-text_bold.woff2') format('woff2'),
    url('../fonts/golos-text_bold.woff') format('woff'),
    url('../fonts/golos-text_bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700
}

@font-face {
    font-family: 'GolosText';
    src: url('../fonts/golos-text_black.woff2') format('woff2'),
    url('../fonts/golos-text_black.woff') format('woff'),
    url('../fonts/golos-text_black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900
}