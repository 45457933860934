.version-block-title {
  font-weight: bold;
  font-size: 20px;
  padding-left: 5px;
}

.version-block-title-color {
  font-size: 16px;
  line-height: 20px;
  background: #F4F4F5;
  border-bottom: 1px solid rgba(16, 24, 40, 0.1);
  border-left: 1px solid rgba(16, 24, 40, 0.1);
  border-right: 1px solid rgba(16, 24, 40, 0.1);

  &.first-agree {
    max-width: 551px;
  }
}

.version-block-item-title {
  font-weight: bold;
  font-size: 16px;
  padding-left: 5px;

  &.first-agree {
    max-width: 551px;
  }
}

.version-block-item-title-color {
  font-size: 14px;
  line-height: 20px;
  background: #F4F4F5;
  border-bottom: 1px solid rgba(16, 24, 40, 0.1);
  border-left: 1px solid rgba(16, 24, 40, 0.1);
  border-right: 1px solid rgba(16, 24, 40, 0.1);
}

.table-diff-design {
  border-left: 1px solid rgba(16, 24, 40, 0.1);
  border-right: 1px solid rgba(16, 24, 40, 0.1);
}

.table-diff {
  width: 100%;

  &.first-agree {
    max-width: 551px;
  }

  td {
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
    padding: 5px;
    border-top: none;
    border-bottom: 1px solid rgba(16, 24, 40, 0.1);
  }

  &.no-bottom-border {
    tr:not(:last-child) {
      td {
        border-bottom: 0;
      }
    }
  }

  .tr-title {
    display: table-row-group;
    border: 1px solid rgba(16, 24, 40, 0.1);
  }

  .table-header {
    background: #F4F4F5;
    align-items: normal;
  }

  .title-version {
    width: 250px;
    font-weight: bold;
    font-size: 20px;
    vertical-align: middle;
  }

  .title-color {
    color: rgba(16, 24, 40, 0.5);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid rgba(16, 24, 40, 0.1);

    &.no-bottom-border {
      border-bottom: 0;
    }
  }

  .link {
    &:hover {
      color: var(--primary-color);
    }
  }

  .title {
    width: 250px;
  }

  .data {
    width: 300px;
  }

  .first.different {
    text-decoration: line-through;
  }

  .second.different {
    color: #ff5e2d;
  }

  .second.append {
    color: #00d359;
  }
}

@media (max-width: 1000px) {
  .table-diff {
    &.table-diff-header {
      width: 99%;
    }

    .table-diff-row {
      .title {
        width: 30%
      }

      .data {
        width: 35%;
      }
    }

    .table-diff-row:not(.table-header) {
      .title {
        word-break: break-all;
      }
    }
  }
}

.data-title {
  padding-right: 0;

  &.first-agree {
    max-width: 551px;
  }
}

.passport-info {
  .accordion-item-felix {
    margin-bottom: 0;
  }
}