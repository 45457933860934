.rc-slider.rt-range {

  margin: 5px 10px 20px;
  width: calc(100% - 20px);

  .rc-slider-rail {
    margin-left: -10px;
    width: calc(100% + 20px);
  }

  .rc-slider-tooltip {
    .rc-slider-tooltip-arrow {
      display: none;
    }

    .rc-slider-tooltip-inner {
      box-shadow: none;
      background-color: transparent;
      color: #101828;
      font-size: 10px;
      padding: 0;
      height: initial;
    }
  }

  .rc-slider-track {
    background-color: var(--primary-color);
  }

}

.rt-range-title__container {
  color: rgba(16, 24, 40, 0.7);
  font-size: 12px;
  margin-bottom: 10px;
}

.rt-range-title__tooltip {
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
}
