.main-panel > .footer {
  height: 50px;
  padding: 0 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    margin-right: 30px;
    a {
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
    }
  }
  .copyright {
    color: #9b9b9b;
    font-family: 'Open Sans';
    font-size: 11px;
    line-height: 15px;
    text-align: right;
  }
}
