@keyframes ldio-duexmvz2gq4 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-duexmvz2gq4 div {
  left: 99px;
  top: 75px;
  position: absolute;
  animation: ldio-duexmvz2gq4 linear 1s infinite;
  background: var(--primary-color);
  width: 2px;
  height: 10px;
  border-radius: 1px / 5px;
  transform-origin: 1px 25px;
}
.ldio-duexmvz2gq4 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: var(--primary-color);
}
.ldio-duexmvz2gq4 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: var(--primary-color);
}

.loadingio-spinner-spinner-utmq1230pj {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ldio-duexmvz2gq4 {
  width: 200px;
  height: 200px;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-duexmvz2gq4 div {
  box-sizing: content-box;
}
