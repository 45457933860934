.appearance-enter{
    opacity: 0;
}
.appearance-enter-active{
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.appearance-exit{
    opacity: 1;
}

.appearance{
    opacity: 0;
    transition: opacity 500ms ease-in;
}