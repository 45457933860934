body,
.wrapper {
  min-height: 100dvh;
  position: relative;
}

.wrapper {
  height: 100vh;

  &.with-app-msg {
    min-height: calc(100vh - 20px);
    height: calc(100vh - 20px);

    .sidebar-wrapper {
      height: calc(100vh - 64px - 20px);
    }
  }
}

.main-panel,
.sidebar {
  max-height: 100%;
  height: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #4a4a4a;

  &:focus,
  &:hover {
    text-decoration: none;
    color: #4a4a4a;
  }
}

.sidebar {
  max-height: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  display: block;
  z-index: 1;
  color: $sidebar-color;
  font-weight: 200;
  transition-duration: 0.2s, 0.2s, 0.35s;
  background-color: $sidebar-bg;

  &-mobileMenuIcon {
    display: none;
  }

  .sidebar-wrapper {
    background-color: $sidebar-bg;
    height: calc(100vh - 64px);
    padding-top: 40px;
    border-right: 1px solid #e8e8ee;
  }

  .brand {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 64px;
    width: 100%;
    background-color: white;
  }

  .user-wrapper {
    .user {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      .photo {
        width: 61px;
        height: 61px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        margin-right: 10px;
      }

      .userinfo {
        flex-grow: 1;

        .username {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }

        .title {
          font-size: 12px;
          font-weight: 300;
          line-height: 17px;
        }
      }

      .collapse-arrow {
        font-size: 30px;
        transition: all 0.3s ease;
      }

      .collapse-arrow.active {
        transform: rotate(180deg);
      }
    }

    .user-nav > li > a {
      padding-left: 90px;
      color: white;

      &:hover,
      &:active {
        color: var(--primary-color);
      }

      &:focus {
        color: white;
        background: transparent;
      }
    }
  }

  > .sidebar-wrapper > .nav {
    > li {
      &.active > a {
        color: var(--primary-color);

        & div {
          color: var(--primary-color);
        }

        & svg {
            & path {
              fill: var(--primary-color);
            }
        }

        & svg.stroke {
          & path {
            fill: transparent;
            stroke: var(--primary-color);
          }
        }
      }

      > a {
        padding: 20px 30px;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #101828;

        & svg {
          margin-right: 6px;
          min-width: 24px;
        }

        &:hover {
          & div {
            color: var(--primary-color);
          }

          & svg {
            & path {
              fill: var(--primary-color);
            }
          }

          & svg.stroke {
            & path {
              fill: transparent;
              stroke: var(--primary-color);
            }
          }
        }
      }

      &.active .caret {
        transform: rotate(180deg);
      }

      > div > ul.nav {
        background: $sidebar-sub-menu-bg;
        list-style-type: circle;

        > li {
          &.active a {
            background: white;
            color: var(--primary-color);
          }

          > a {
            padding-left: 74px;
            font-size: 13px;
            color: black;

            &:hover {
              background: white;
              color: var(--primary-color);
            }
          }
        }
      }

      &.nav {

        &_tabletItem,
        &_mobileItem {
          display: none;
        }
      }
    }

    i {
      font-size: 20px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 13px;
      color: #101828;
      white-space: nowrap;
      position: relative;

      .caret {
        margin-left: 10px;
        transition: all 0.2s ease;
      }
    }
  }

  .line {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #a1a7bb;
    opacity: 0.1;
  }
}

.nav li a {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.main-panel {
  position: relative;
  float: right;
  min-height: 100%;
  overflow: auto;
  box-shadow: 0 30px 130px 0 rgba(90, 105, 116, 0.1);
}

.close-layer {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: auto;
  content: '';
  z-index: 1000;
  overflow-x: hidden;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.35);
}

@media (max-width: 1000px) {

  body,
  .wrapper {
    min-height: calc(100dvh - 64px);
  }

  .wrapper {
    height: calc(100vh - 64px);
  }

  .sidebar {
    position: absolute;
    z-index: 10002;
    background: transparent;
    pointer-events: none;

    &-layer {
      position: absolute;
      z-index: 10001;
      width: 100%;
      height: 100%;
      margin-top: 64px;
      background: rgba(16, 24, 40, 0.5);
    }
  }

  .brand {
    pointer-events: all;
    padding-left: 10px;
  }

  .main-panel {
    width: calc(100% - 52px);
  }

  .sidebar > .sidebar-wrapper > .nav > li {
    height: 56px;
    display: flex;
    align-items: center;

    &.nav {
      &_tabletItem {
        display: block;
      }
    }

    & > a {
      padding: 16px;
      font-size: 13px;
      line-height: 20px;

      &.nav_link__manual {
        white-space: normal;
      }

      svg {
        flex-shrink: 0;
        margin-right: 15px;
        transition: margin linear 0.3s;
      }
    }
  }

  .nav {
    &_close {
      padding: 16px;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #101828;
      font-weight: 400;

      svg {
        margin-right: 15px;
        flex-shrink: 0;
        transition: margin linear 0.3s;
      }
    }
  }

  .sidebar .sidebar-wrapper {
    padding-top: 26px;
    padding-bottom: 4px;
    width: 52px;
    transition: width linear 0.3s;
    pointer-events: all;
    overflow: hidden;
    overflow-y: auto;

    &__open {
      width: 195px;

      & > .nav > li > a svg {
        margin-right: 6px;
      }

      .nav_close {
        svg {
          margin-right: 6px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sidebar > .sidebar-wrapper > .nav > li > a {
    &:hover {
      color: #101828;

      div {
        color: #101828;
      }

      svg {
        path {
          fill: #101828;
        }
      }
    }
  }

  .sidebar > .sidebar-wrapper > .nav > li.active > a {
    &:hover {
      color: var(--primary-color);

      div {
        color: var(--primary-color);
      }

      svg {
        path {
          fill: var(--primary-color);
        }
      }

      svg.stroke {
        path {
          fill: none;
          stroke: var(--primary-color);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    min-height: 100dvh;
  }

  .wrapper {
    height: 100%;
    min-height: 100%;

    &.with-app-msg {
      .sidebar-mobileMenuIcon {
        top: 36px;
      }

      .sidebar {
        top: 20px;
      }

      .sidebar-wrapper {
        height: 100%;
      }
    }
  }

  .sidebar {
    width: 100%;
    position: fixed;

    &-layer {
      display: none;
    }

    &-mobileMenuIcon {
      display: block;
      position: fixed;
      top: 16px;
      right: 16px;
      z-index: 10003;
    }

    &__open + .navbar + .main-panel {
      max-height: 90vh;
      overflow: hidden;
      transition: all linear 0.3s;
    }
  }

  .main-panel {
    width: 100%;
    padding-top: 56px;
    float: none;
  }

  .sidebar .brand {
    height: 56px;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 10px 0 50px 0 rgb(61 110 146 / 10%);
  }

  .sidebar .sidebar-wrapper {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    right: 100%;
    transition: right linear 0.3s;

    &__open {
      right: 0;
      overflow: auto;
    }

    & > .nav > li {
      height: auto;

      &.nav {
        &_mobileItem {
          display: block;

          div {
            display: flex;
            align-items: center;

            span {
              margin-left: 5px;
            }
          }
        }

        &_tabletItem,
        &_desktopItem {
          display: none;
        }
      }

      & > a {
        padding: 20px;
        padding-bottom: 12px;
        font-size: 20px;
        line-height: 28px;
        width: 100%;

        &.nav_link__user {
          font-size: 14px;
          line-height: 20px;
          padding: 20px 0;
          margin: 0 20px 10px;
          border-bottom: 1px solid #CCCCCC;
          width: calc(100% - 40px);
        }

        svg {
          margin-right: 12px;
        }
      }
    }
  }

  .nav {
    &_logout {
      padding: 20px;
      padding-bottom: 18px;
      font-size: 20px;
      line-height: 28px;
      width: 100%;
      display: flex;
      align-items: center;
      color: #101828;
      font-weight: 400;
      pointer-events: all;

      svg {
        margin-right: 12px;
        flex-shrink: 0;
      }
    }
  }
}

@media (min-width: 1001px) {
  .sidebar.open-desktop + * + .main-panel {
    width: calc(100% - 280px);
  }

  .sidebar.close-desktop + * + .main-panel {
    width: calc(100% - 62px);
  }

  .sidebar,
  .sidebar > .sidebar-wrapper > .nav > li > a,
  .sidebar.close-desktop .collapse-icon svg {
    transition: none;
  }

  .sidebar.close-desktop {
    width: 62px;
  }

  .sidebar.close-desktop > .sidebar-wrapper > .nav > li > a svg {
    margin-right: 0;
  }

  .sidebar.close-desktop > .sidebar-wrapper > .nav > li > a {
    padding: 20px 10px;
  }

  .sidebar.close-desktop .nav-item__name {
    display: none;
  }

  .sidebar.open-desktop .nav-item__closed {
    display: none;
  }


  .main-panel {
    min-height: calc(100% - 64px);
    height: calc(100% - 64px);
  }

  .sidebar.close-desktop .sidebar-wrapper,
  .sidebar.open-desktop .sidebar-wrapper {
    overflow-x: hidden;
  }

  .sidebar.close-desktop .collapse-icon svg {
    transform: rotate(180deg);
  }

  .sidebar.close-desktop .nav-felix {
    align-items: center;
  }
}