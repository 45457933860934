.felix-toast-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  top: 80px;
  right: 30px;
}

.felix-toast-top-right {
   top: 0px;
   right: 0px;
   transition: transform .6s ease-in-out;
   animation: toast-in-right .7s;
 }

.felix-toast-bottom-right {
  bottom: 12px;
  right: 30px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.felix-toast-plate {
  background: white;
  transition: .3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 15px;
  width: 300px;
  max-height: 100px;
  border-radius: 6px;
  box-shadow: 0 0 6px #999;
  //opacity: .3;
  background-position: 15px;
  background-repeat: no-repeat;
}

.felix-toast:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer
}

.felix-toast-message {
  margin: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2px;
}

.felix-toast-container button {
  position: relative;
  float: right;
  font-size: 10px;
  padding: 0;
  cursor: pointer;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);

  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);

  }
  to {
    transform: translateX(0);
  }
}