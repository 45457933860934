.rt-pagination {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 1.5rem 40px;
  margin: 0;
  margin-top: 40px;

  &.video {
    margin-top: 0;
    display: flex;
    justify-content: center;
  }
}

.pagination > li > a,
.pagination > li > span {
  background-color: #E8E8EE;
  border: none;
  // margin: 0.5rem;
  border-radius: 8px;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 8px;
}

.page-item.disabled {
  background: inherit;
}

.page-item + .page-item {
  margin-left: .5rem;
}


.page-item.active .page-link,
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background: var(--primary-color);
  color: #FFFFFF;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #101828;
  line-height: initial;
  padding: 6px 8px;
  min-width: 2em;
  text-align: center;
}

@media (max-width: 880px) {
  .rt-pagination {
    justify-content: center;
  }

  .pagination > li > a,
  .pagination > li > span,
  .pagination > li > a:focus,
  .pagination > li > a:hover,
  .pagination > li > span:focus,
  .pagination > li > span:hover {
    padding: 5px 9px;
    min-width: 1em;
  }
}

@media (max-width: 768px) {
  .pagination > li > a,
  .pagination > li > span,
  .pagination > li > a:focus,
  .pagination > li > a:hover,
  .pagination > li > span:focus,
  .pagination > li > span:hover {
    min-width: 2em;
  }

  .rt-pagination {
    margin-top: 16px;
  }
}