.dashboard-table__container {
  background: white;

  .table-responsive {
    max-height: 40vh;
  }

  .table th,
  .table td {
    padding: 0.2rem 0.5rem;
  }
}

.dashboard-table__header-filter {
  td {
    padding: 0;
  }

  .input-title__container {
    margin: 0;
    position: relative;
  }

  .input-title__container-main {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .input-title__description {
    display: none;
  }

  .select-rt__indicators {

    .select-rt__dropdown-indicator,
    .select-rt__indicator-separator {
      display: none;
    }
  }
}

.dashboard-pie__item {
  min-height: 300px;
}

.dashboard-line__item {
  min-height: calc(300px + 64px);
}

.dashboard-diagrams__container {
  .data-picker-double__container {
    max-width: 200px;
    margin-left: auto;

    .react-datepicker-wrapper {
      margin: 0;
    }

    .input-title__container-main {
      padding: 0;
      background: transparent;
      border: none;
      border-radius: 0;
      font-size: 13px;
    }
  }
}

.dashboard__item-container {
  margin: 0px -5px;
  margin-bottom: 10px;
}

.dashboard__item-container-margin-bottom {
  margin-bottom: 10px;
}

.dashboard__item {
  background-color: white;
  padding: 1rem;
  // margin: 0 -10px;
}

.dashboard__item-text {
  font-weight: bold;
  font-size: 16px;
  color: #666;
}

.date-label-range-select__item {
  display: inline-block;
}

.date-label-range-select__item + .date-label-range-select__item {
  margin-left: 1rem;
}

.dashboard-date__container {
  display: flex;

  .input-title__container {
    margin: 0;
  }
}

.date-label-range-select__container {
  font-size: 13px;
  margin: auto 0;
}

.dashboard-table__container {
  .color-indicator {
    transform: scale(2);
  }
}

.gantt-container .popup-wrapper .title a {
  color: white;
}

.gantt-item__container:not(.CPM).STATUS_DICT {
  &.GROUP {
    font-weight: bold;

    &.PLAN {
      background-color: hsl(0deg 0% 47%);
    }

    &.WORK {
      background-color: hsl(210deg 41% 40%);
    }

    &.SUCCESS {
      background-color: hsl(127deg 51% 31%);
    }

    &.CANCEL {
      background-color: hsl(212deg 16% 64%);
    }
  }

  &.NO_STATUS.EXPIRED,
  &.WORK.EXPIRED,
  &.PLAN.EXPIRED {
    background-color: #e61212;

    &.milestone .gantt-item__title {
      background-color: #e61212;
    }
  }

  &.WORK {
    background-color: #6f99c3
  }

  &.PLAN {
    background-color: #acacac;
  }

  &.SUCCESS {
    background-color: #41c151;
  }

  &.CANCEL {
    background-color: hsl(210deg 16% 84%);
  }
}

.gantt-item__container.CPM {
  background-color: #FF33CC;
}

.gantt-container {
  .bar-wrapper.STATUS_DICT {
    &.GROUP {
      text {
        font-weight: bold;
      }

      &.PLAN {
        .bar-progress,
        .bar-secondary-progress {
          fill: hsl(0deg 0% 47%);
        }
      }

      &.WORK {
        .bar-progress,
        .bar-secondary-progress {
          fill: hsl(210deg 41% 40%);
        }
      }

      &.SUCCESS {
        .bar-progress,
        .bar-secondary-progress {
          fill: hsl(127deg 51% 31%);
        }
      }

      &.CANCEL {
        .bar-progress,
        .bar-secondary-progress {
          fill: hsl(212deg 16% 64%);
        }
      }
    }

    &.WORK.EXPIRED,
    &.PLAN.EXPIRED {
      .bar-progress,
      .bar-secondary-progress {
        fill: #e61212;
      }
    }

    &.WORK {
      .bar-progress,
      .bar-secondary-progress {
        fill: #6f99c3
      }
    }

    &.PLAN {
      .bar-progress,
      .bar-secondary-progress {
        fill: #acacac;
      }
    }

    &.SUCCESS {
      .bar-progress,
      .bar-secondary-progress {
        fill: #41c151
      }
    }

    &.CANCEL {
      .bar-progress,
      .bar-secondary-progress {
        fill: hsl(210deg 16% 84%);
      }
    }

    &.MILESTONE {
      .bar-progress,
      .bar-secondary-progress {
        fill: #cf89d5
      }
    }
  }
}

.dashboard-report-link {
  .dashboard-report-link_icon {
    margin-left: 10px;
  }
}