@media (max-width: 768px) {
  .budget {
    .h3-felix {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media (max-width: 600px) {
  .budget {
    .h3-felix {
      font-size: 16px;
      line-height: 20px;
    }

    .h5-felix {
      font-size: 14px;
      line-height: 20px;
    }

    .input-title__label {
      font-size: 14px;
    }
  }
}